var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Card, Table, TableBody, TableContainer } from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
import { TableHeadCustom, TableNoData, TablePaginationCustom, useTable, } from '../../../components/table';
import { IStatus } from '../../../@types/status';
import LoadingTable from '../../../components/loadingTable/LoadingTable';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { deleteOneBookStock } from '../../../redux/slices/bookStocks/actions';
import BookSaleTableRow from './BookSaleTableRow';
import { getAllBookSales } from '../../../redux/slices/bookSales/actions';
var TABLE_HEAD = [
    {
        id: 'office',
        label: 'office',
        align: 'left',
    },
    {
        id: 'details',
        label: 'Details',
        align: 'left',
    },
    {
        id: 'totalAmount',
        label: 'Total Amount',
        align: 'center',
    },
    // {
    //   id: 'price',
    //   label: 'price',
    //   align: 'left',
    // },
    // {
    //   id: 'description',
    //   label: 'description',
    //   align: 'left',
    // },
    {
        id: 'createdBy',
        label: 'Created By',
        align: 'center',
    },
    {
        id: 'note',
        label: 'Note',
        align: 'center',
    },
    { id: 'createdAt', label: 'created At', align: 'center' },
    { id: '__action', label: 'Actions', align: 'center' },
];
var BookSaleTable = function () {
    var _a;
    var _b = useTable({ defaultOrderBy: 'createdAt', defaultOrder: 'desc' }), dense = _b.dense, page = _b.page, order = _b.order, orderBy = _b.orderBy, rowsPerPage = _b.rowsPerPage, setPage = _b.setPage, selected = _b.selected, onSort = _b.onSort, onChangeDense = _b.onChangeDense, onChangePage = _b.onChangePage, onChangeRowsPerPage = _b.onChangeRowsPerPage, onSelectRow = _b.onSelectRow;
    var enqueueSnackbar = useSnackbar().enqueueSnackbar;
    var navigate = useNavigate();
    // Books Table Data
    var _c = useState([]), tableData = _c[0], setTableData = _c[1];
    // Search
    var _d = useState(''), searchValue = _d[0], setSearchValue = _d[1];
    var isFiltered = searchValue !== '';
    // Get Books
    useEffect(function () {
        dispatch(getAllBookSales({
            page: page,
            limit: rowsPerPage,
            search: searchValue,
        }));
    }, [page, rowsPerPage, dispatch, searchValue]);
    var _e = useSelector(function (store) { return store.bookSales; }), bookSales = _e.bookSales, status = _e.status;
    var isNotFound = IStatus.SUCCEEDED && !(tableData === null || tableData === void 0 ? void 0 : tableData.length);
    useEffect(function () {
        setTableData(bookSales.docs);
    }, [bookSales]);
    var dataInPage = tableData === null || tableData === void 0 ? void 0 : tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    var handleDeleteRow = function (id) {
        dispatch(deleteOneBookStock(id))
            .unwrap()
            .then(function (res) { return enqueueSnackbar("".concat(res === null || res === void 0 ? void 0 : res.message)); })
            .catch(function (err) { return enqueueSnackbar("".concat(err === null || err === void 0 ? void 0 : err.message), { variant: 'error' }); });
        if (page > 0) {
            if (dataInPage.length < 2) {
                setPage(page - 1);
            }
        }
    };
    var handleEditRow = function (row) {
        navigate("".concat(PATH_DASHBOARD.books.bookStocks.edit(row._id)), { state: { bookStock: row } });
    };
    var handleViewRow = function (row) {
        navigate("".concat(PATH_DASHBOARD.books.bookStocks.view(row._id)), { state: { bookStock: row } });
    };
    var handleResetFilter = function () {
        setSearchValue('');
        setPage(0);
    };
    var handleSearch = function (event) {
        setPage(0);
        setSearchValue(event.target.value);
    };
    var denseHeight = dense ? 52 : 72;
    return (_jsxs(Card, { children: [_jsx(TableContainer, __assign({ sx: { position: 'relative', overflow: 'unset' } }, { children: _jsx(Scrollbar, __assign({ sx: { overflow: 'auto', maxHeight: 700 } }, { children: _jsxs(Table, __assign({ stickyHeader: true, "aria-label": "sticky table", size: dense ? 'small' : 'medium', sx: { minWidth: 800 } }, { children: [_jsx(TableHeadCustom, { order: order, orderBy: orderBy, headLabel: TABLE_HEAD, rowCount: 6, numSelected: selected.length, onSort: onSort }), _jsxs(TableBody, { children: [status === IStatus.LOADING ? (_jsx(LoadingTable, { height: denseHeight, fields: TABLE_HEAD.length, rowsPerPage: rowsPerPage })) : (_jsx(_Fragment, { children: tableData === null || tableData === void 0 ? void 0 : tableData.map(function (row) { return (_jsx(BookSaleTableRow, { row: row, selected: selected.includes(row._id), onSelectRow: function () { return onSelectRow(row._id); }, onDeleteRow: function () {
                                                handleDeleteRow(row._id);
                                            }, onEditRow: function () {
                                                handleEditRow(row);
                                            }, onViewRow: function () {
                                                handleViewRow(row);
                                            } }, row._id)); }) })), status === IStatus.SUCCEEDED && (tableData === null || tableData === void 0 ? void 0 : tableData.length) === 0 && (_jsx(TableNoData, { isNotFound: isNotFound }))] })] })) })) })), _jsx(TablePaginationCustom, { count: ((_a = bookSales === null || bookSales === void 0 ? void 0 : bookSales.meta) === null || _a === void 0 ? void 0 : _a.totalDocs) || 0, page: page, rowsPerPage: rowsPerPage, onPageChange: onChangePage, onRowsPerPageChange: onChangeRowsPerPage, dense: dense, onChangeDense: onChangeDense })] }));
};
export default BookSaleTable;
